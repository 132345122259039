<template>
  <div class="orderLogisticsStatusPage">
    <HomePageTop class="HomePageTopBox"></HomePageTop>
    <div class="orderLogisticsStatusContainer">
      <header>
        <div class="headerTitle">物流状態</div>
        <div class="headerListContainer">
          <div class="iconContainer">
            <img alt="" src="../../../views/wuliuStatus/assets/icon1.png">
            <img alt="" src="../../../views/wuliuStatus/assets/1.png">
            <img alt="" src="../../../views/wuliuStatus/assets/icon2.png">
            <img alt="" src="../../../views/wuliuStatus/assets/1.png">
            <img alt="" src="../../../views/wuliuStatus/assets/icon3.png">
            <img alt="" src="../../../views/wuliuStatus/assets/1.png">
            <img alt="" src="../../../views/wuliuStatus/assets/icon4.png">
            <img alt="" src="../../../views/wuliuStatus/assets/1.png">
            <img alt="" src="../../../views/wuliuStatus/assets/_s-icon5.png">
          </div>
          <div class="textListContainer">
            <div class="textContainer">
              <div>買付中</div>
              <div>{{ chinaLogisticsStatusInfo.time1 }}</div>
            </div>
            <div class="textContainer">
              <div>配送待ち</div>
              <div>{{ chinaLogisticsStatusInfo.time2 }}</div>
            </div>
            <div class="textContainer">
              <div>配送中</div>
              <div>{{ chinaLogisticsStatusInfo.time3 }}</div>
            </div>
            <div class="textContainer">
              <div>到着作業待ち</div>
              <div>{{ chinaLogisticsStatusInfo.time4 }}</div>
            </div>
            <div class="textContainer">
              <div>到着作業中</div>
              <div>{{ chinaLogisticsStatusInfo.time5 }}</div>
            </div>
          </div>
        </div>
      </header>
      <footer>
        <div class="headerTitle">物流の状況:</div>
        <div class="footerListContainer">
          <div class="itemContainer">
            <div class="itemLeftContainer">
              <img alt="" src="../../../views/wuliuStatus/assets/time.png">
              <div>{{ chinaLogisticsStatusInfo.time1 }}</div>
            </div>
            <div class="itemRightContainer">買付中</div>
          </div>
          <div class="itemContainer">
            <div class="itemLeftContainer">
              <img alt="" src="../../../views/wuliuStatus/assets/time.png">
              <div>{{ chinaLogisticsStatusInfo.time2 }}</div>
            </div>
            <div class="itemRightContainer">配送待ち</div>
          </div>
          <div class="itemContainer">
            <div class="itemLeftContainer">
              <img alt="" src="../../../views/wuliuStatus/assets/time.png">
              <div>{{ chinaLogisticsStatusInfo.time3 }}</div>
            </div>
            <div class="itemRightContainer">配送中</div>
          </div>
          <div class="itemContainer">
            <div class="itemLeftContainer">
              <img alt="" src="../../../views/wuliuStatus/assets/time.png">
              <div>{{ chinaLogisticsStatusInfo.time4 }}</div>
            </div>
            <div class="itemRightContainer">到着作業待ち</div>
          </div>
          <div class="itemContainer" style="border-bottom: none">
            <div class="itemLeftContainer">
              <img alt="" src="../../../views/wuliuStatus/assets/time.png">
              <div>{{ chinaLogisticsStatusInfo.time5 }}</div>
            </div>
            <div class="itemRightContainer">到着作業中</div>
          </div>
        </div>
      </footer>
    </div>
    <Foot/>
  </div>
</template>

<script>
import HomePageTop from "@/components/head/HomePageTop";
import Foot from "@/components/foot/Foot";
import HowToUseCoupons from "@/views/footList/components/userGuide/howToUseCoupons";

export default {
  data() {
    return {
      chinaLogisticsStatusInfo: {
        time1: '',
        time2: '',
        time3: '',
        time4: '',
        time5: ''
      }
    };
  },
  components: {
    HowToUseCoupons,
    HomePageTop,
    Foot
  },
  created() {
    this.$api.chinaLogisticsStatus({
      order_sn: this.$route.query.order_sn,
      order_detail_id: this.$route.query.order_detail_id
    }).then((res) => {
      for (let i = 0; i < res.data.node_list.length; i++) {
        switch (res.data.node_list[i].name) {
          case '已到账待采购':
            this.chinaLogisticsStatusInfo.time1 = res.data.node_list[i].datetime;
            break;
          case '待发货':
            this.chinaLogisticsStatusInfo.time2 = res.data.node_list[i].datetime;
            break;
          case '已发货':
            this.chinaLogisticsStatusInfo.time3 = res.data.node_list[i].datetime;
            break;
          case '已签收':
            this.chinaLogisticsStatusInfo.time4 = res.data.node_list[i].datetime;
            break;
          case '待检品':
            this.chinaLogisticsStatusInfo.time5 = res.data.node_list[i].datetime;
            break;
        }
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.orderLogisticsStatusPage {
  background: #fff;

  .headerTitle {
    color: #333333;
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .orderLogisticsStatusContainer {
    width: 1200px;
    margin: 0 auto 40px;

    header {
      margin: 40px 0;
    }

    .headerListContainer {
      width: 100%;
      height: 236px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.1);
      padding: 40px 80px 0 80px;
      box-sizing: border-box;

      .iconContainer {
        margin: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .textListContainer {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .textContainer {
          color: #333333;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 150px;

          div:first-child {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 9px;
          }

          div:last-child {
            font-size: 14px;
            height: 20px;
          }
        }
      }
    }

    .footerListContainer {
      border-radius: 10px;
      border: 1px solid #DFDFDF;
      background: #F6F6F6;

      .itemContainer {
        padding: 15px 0 15px 30px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #DFDFDF;

        .itemLeftContainer {
          display: flex;
          align-items: center;
          border-right: 1px solid #DFDFDF;
          padding-right: 20px;
          width: 205px;

          img {
            margin-right: 10px;
          }
        }

        .itemRightContainer {
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
