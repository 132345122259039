<template>
  <div>
    <div class="title">{{ $fanyi("优惠券使用方法") }}</div>
    <div style="margin: 32px 0 18px 0" class="fontWeightBold fontSize18">クーポン券の確認</div>
    <div style="margin-bottom:18px" class="lineHeight32">
      ①マイページへ
    </div>
    <img alt="" class="gif4" src="../../../../assets/userGuide/4.gif" @click="imgFullscreen('.gif4')">
    <div style="margin: 42px 0 18px 0" class="lineHeight32">
      ②クーポン券をクリック
    </div>
    <img alt="" class="gif5" src="../../../../assets/userGuide/5.gif" @click="imgFullscreen('.gif5')">
    <div style="margin: 42px 0 18px 0" class="lineHeight32">
      ③利用可能のクーポンを確認
    </div>
    <img alt="" class="gif6" src="../../../../assets/userGuide/6.gif" @click="imgFullscreen('.gif6')">
    <div style="margin: 42px 0 18px 0" class="fontWeightBold fontSize18">クーポン券 利用</div>
    <div style="margin-bottom:18px" class="lineHeight32">
      ①注文書へ。
      ②支払い待ちの注文書を選択
    </div>
    <img alt="" class="gif7" src="../../../../assets/userGuide/7.gif" @click="imgFullscreen('.gif7')">
    <div style="margin: 42px 0 18px 0" class="lineHeight32">
      ③支払い待ち注文書詳細より、クーポン利用をチェッ<br>
      ④利用される券を選択し、確定をクリック<br>
      ⑤詳細ページより割引額などを確認<br>
    </div>
    <img alt="" class="gif8" src="../../../../assets/userGuide/8.gif" @click="imgFullscreen('.gif8')">
  </div>
</template>

<script>
export default {
  methods: {
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
  height: 496px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
